<template>
  <section class="filter-wrap">
    <BasicFilterWrap
      :moreFilter="showMoreFilter"
      @confrim="onSearch"
      @reset="onResetFilter"
      @more="showMoreFilter = !showMoreFilter"
    >
      <el-form ref="form" :model="tableFilters" label-width="auto">
        <el-row :gutter="20">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="中文名称">
              <el-input
                v-model="tableFilters.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="英文名称">
              <el-input
                v-model="tableFilters.typeId"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="tableFilters.createdAt"
                type="daterange"
                style="width: 100%"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-collapse-transition>
          <el-row :gutter="10" v-if="showMoreFilter">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建人">
                <BasicSelect
                  v-model="tableFilters.createdById"
                  :allOptions="_userOptions"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="最后修改日期">
                <el-date-picker
                  v-model="tableFilters.lastModifiedAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="最后修改人">
                <BasicSelect
                  v-model="tableFilters.lastModifiedById"
                  :allOptions="_userOptions"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-transition>
      </el-form>
    </BasicFilterWrap>
  </section>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/table";
import BasicFilterWrap from "@/components/BasicFilterWrap";
export default {
  components: {
    BasicSelect,
    BasicFilterWrap,
  },
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: null,
        lastModifiedAt: null,
        createdById: null,
        name: "",
        typeId: null,
      },
    };
  },
  computed: {
    _userOptions() {
      return this.$store.getters.userOptions;
    },
  },
  methods: {
    onSearch() {
      this.$emit("change", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.$emit("change", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
}
</style>