import { api } from "@/request/api";
import qs from "qs";

let baseUrl = '/pages/sections/types'

// 获取产品颜色的分页列表
export function GetPageSectionsTypeList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        api.get(`${baseUrl}?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的产品颜色
export function GetPageSectionsTypeById(id = null) {
    return new Promise((resolve, reject) => {
        api.get(`${baseUrl}/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function PostPageSectionsType(params = []) {
    return new Promise((resolve, reject) => {
        api.post(`${baseUrl}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function PutPageSectionsTypeById(id = null, params = []) {
    return new Promise((resolve, reject) => {
        api.put(`${baseUrl}/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function DeletePageSectionsTypeByIds(ids = [], bool = null) {
    return new Promise((resolve, reject) => {
        api.delete(`${baseUrl}?ids=${ids}`)
            .then(res => {
                if (res.status === 200 || res.status === 204) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
export function GetDeleteCheck(ids = []) {
    return new Promise((resolve, reject) => {
        api.get(`${baseUrl}/can/delete?ids=${ids}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
export function GetUpdateCheck(id = null) {
    return new Promise((resolve, reject) => {
        api.get(`${baseUrl}/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}
