<template>
  <el-form
    :model="form"
    ref="form"
    label-width="100px"
    class="company-ruleForm"
    label-position="top"
  >
    <!-- <el-alert
      :closable="false"
      title="Tips：输入完成后敲击回车可直接新增一行"
      type="info"
    >
    </el-alert> -->
    <el-row :gutter="10" v-for="(item, index) in form.items" :key="item.key">
      <el-col :span="8">
        <el-form-item
          label="名称"
          :prop="'items.' + index + '.name'"
          :rules="[
            { required: true, message: '请输入名称', trigger: 'blur' },
          ]"
        >
          <el-input
            :disabled="submitting"
            v-model="item.name"
            clearable
            :ref="`input${index}`"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="类型ID"
          :prop="'items.' + index + '.typeId'"
          :rules="[
            { required: true, message: '请输入类型ID', trigger: 'blur' },
          ]"
        >
          <el-input
            :disabled="submitting"
            v-model.trim="item.typeId"
            clearable
            :ref="`input${index}`"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item
          label="次序"
          :prop="'items.' + index + '.seq'"
          :rules="[{ required: true, message: '请输入次序', trigger: 'blur' }]"
        >
          <el-input
            :disabled="submitting"
            v-model.trim="item.seq"
            clearable
            :ref="`input${index}`"
            placeholder="请输入"
            type="number"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4" v-if="form.items.length > 1">
        <el-form-item label="操作" prop="userCountLimit"
          ><el-button
            type="danger"
            @click="onDeleteRow(index)"
            :disabled="submitting"
            >删除</el-button
          ></el-form-item
        >
      </el-col>
    </el-row>
    <el-form-item style="text-align: right; margin-top: 30px">
      <el-button @click="onCancel" :disabled="submitting">关 闭</el-button>
      <el-button @click="addRow" :disabled="submitting">新增一行</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >提交创建</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { createUniqueString } from "@/utils/common";
import { PostPageSectionsType } from "../api";
import { ShowApiError } from "@/request/error";
export default {
  data() {
    return {
      submitting: false,
      focusIndex: 0,
      form: {
        items: [
          {
            address: {
              city: "",
              country: "",
              province: "",
              region: "",
              street: "",
            },
            description: "",
            name: "",
            seq: 0,
            typeId: "",
            key: this.createUniqueString(),
          },
        ],
      },
    };
  },
  methods: {
    createUniqueString,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datas = [];
          this.form.items.forEach((item) => {
            datas.push({
              address: item.address,
              description: item.description,
              name: item.name,
              seq: item.seq,
              typeId: item.typeId,
              seq: item.seq,
            });
          });
          this.submitting = true;
          PostPageSectionsType(datas)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("新增成功");
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError(err, "批量新增异常");
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    initForm() {
      this.form = {
        items: [
          {
            address: {
              city: "",
              country: "",
              province: "",
              region: "",
              street: "",
            },
            description: "",
            name: "",
            seq: 1,
            typeId: "",
            key: this.createUniqueString(),
          },
        ],
      };
    },
    addRow(inputObj = "") {
      this.form.items.push({
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        description: "",
        name: "",
        typeId: "",
        seq: this.form.items.length + 1,
        key: this.createUniqueString(),
      });
      this.$nextTick(() => {
        if (this.$refs[inputObj]) {
          this.$refs[inputObj][0].focus();
        }
      });
    },
    onDeleteRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      }
    },
    onCancel() {
      this.initForm();
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
</style>