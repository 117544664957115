<template>
  <div class="tale-main-page">
    <PageHeader>
      <template #right>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="addRow"
          >新增</el-button
        >
      </template>
    </PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <div class="page-table">
      <basic-table
        showSelection
        hiddenClear
        :loading="tableLoading"
        :tableColumns="tableColumns"
        :tableData="tableData"
        :page="page"
        :size="size"
        :total="total"
        :orderDirection="orderDirection"
        :orderProp="orderProp"
        @onSortChange="onTableSortChange"
        @onSizeChange="handleSizeChange"
        @onPageChange="handlePageChange"
        @onClearAndRefresh="onClearAll"
        @onRefresh="onRefresh"
        @onSelectionChange="onSelectionChange"
      >
        <template #tableControl></template>
        <template v-for="column in tableColumns">
          <el-table-column
            v-if="column.prop !== 'controlColumn' && column.showColumn"
            v-bind="column"
            :show-overflow-tooltip="true"
            :key="column.prop"
          >
            <template slot-scope="scope">
              <span v-if="column.prop === 'createdAt'">
                {{ scope.row.createdAt | date }}
              </span>
              <span v-else-if="column.prop === 'lastModifiedAt'">
                {{ scope.row.lastModifiedAt | date }}
              </span>
              <InlineForm
                v-model="scope.row.nameCn"
                v-else-if="column.prop === 'nameCn' && scope.row.isEdit"
                hiddenConfirm
                hiddenCancel
              />
              <InlineForm
                v-model="scope.row.nameEn"
                v-else-if="column.prop === 'nameEn' && scope.row.isEdit"
                hiddenConfirm
                hiddenCancel
              />
              <InlineForm
                v-model="scope.row.seq"
                v-else-if="column.prop === 'seq' && scope.row.isEdit"
                hiddenConfirm
                hiddenCancel
              />
              <span v-else>{{ scope.row[column.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="column.prop === 'controlColumn' && column.showColumn"
            v-bind="column"
            :key="column.prop"
          >
            <template slot-scope="scope">
              <el-button
                :disabled="!scope.row.canUpdate"
                v-if="!scope.row.isEdit"
                @click.native.prevent="editRow(scope.row, scope.$index)"
                type="text"
                size="small"
              >
                修改
              </el-button>
              <el-button
                v-if="scope.row.isEdit"
                @click.native.prevent="onConfirmEdit(scope.row, scope.$index)"
                type="text"
                size="small"
                icon="el-icon-check"
              >
                确认
              </el-button>
              <el-button
                class="text-danger"
                v-if="scope.row.isEdit"
                @click.native.prevent="onCancelEdit(scope.row, scope.$index)"
                type="text"
                size="small"
                icon="el-icon-close"
              >
                取消
              </el-button>
              <el-button
                class="text-danger"
                :disabled="!scope.row.canDelete"
                v-if="!scope.row.isEdit"
                @click.native.prevent="DeleteRow(scope.row, scope.$index)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </template>
        <template #multipleControl>
          <el-button
            type="danger"
            size="small"
            @click="onMultipleToggleDelete"
            :disabled="multipleSelected.length <= 0"
            >批量删除 {{ multipleSelected.length }}</el-button
          >
        </template>
      </basic-table>
    </div>
    <el-dialog
      title="新增"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="70%"
    >
      <Form @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import InlineForm from "@/components/InlineForm";
import BasicTable from "@/components/BasicTable";
import FilterSection from "./components/FilterSection";
import Form from "./components/Form";
import config from "@/config";
import { isEmprty } from "@/utils/validate";
import { ShowApiError } from "@/request/error";
import {
  getOrderType,
  onClearFilter,
} from "@/utils/common";
import { FormatMultipleItemIds,GetTableDisableMessage } from "@/utils/table";
import {
  GetPageSectionsTypeList,
  PutPageSectionsTypeById,
  DeletePageSectionsTypeByIds,
  GetDeleteCheck,
  GetUpdateCheck,
} from "./api";

export default {
  components: {
    PageHeader,
    BasicTable,
    InlineForm,
    Form,
    FilterSection,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      orderProp: "seq",
      orderDirection: "descending",
      orderPropObj: {},
      tableFilters: {},
      multipleSelected: [],
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "id",
            label: "ID",
            width: 80,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "seq",
            label: "次序",
            width: 80,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "name",
            label: "名称",
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            width: 120,
            showColumn: false,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            width: 120,
            showColumn: false,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "更新日期",
            width: 120,
            showColumn: false,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "lastModifiedByName",
            label: "更新人",
            width: 120,
            showColumn: false,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "controlColumn",
            fixed: "right",
            align: "center",
            showColumn: true,
            label: "操作",
            width: 130,
          },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
        return `${this.orderProp},${getOrderType(this.orderDirection)}`;
      }
    },
    filterParams() {
      return { ...this.tableFilters };
    },
    getTableData() {
      this.tableLoading = true;
      GetPageSectionsTypeList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDelete: true,
              isEdit: false,
            };
          });
          this.tableData.forEach((item) => {
            this.onCheckUpdate(item);
            this.onCheckDelete(item);
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError(err, "拉取列表错误");
        });
    },
    onCheckUpdate(item) {
      GetUpdateCheck(item.id)
        .then((res) => {
          item.canUpdate = res.data;
        })
        .catch(() => {
          item.canUpdate = true;
        });
    },
    onCheckDelete(item) {
      GetDeleteCheck([item.id])
        .then((res) => {
          item.canDelete = res.data;
        })
        .catch(() => {
          item.canDelete = true;
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onRefresh();
    },
    onClearAll() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onSearch();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.page = 0;
      this.onRefresh();
    },
    addRow() {
      this.formDialog = true;
    },
    editRow(row) {
      if (row.canUpdate) {
        row.isEdit = true;
      } else {
        row.isEdit = false;
        this.$message.warning("没有权限执行此操作!");
      }
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    DeleteRow(row) {
      if (row.canDelete) {
        this.$confirm("此操作将永久删除该数据项, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            DeletePageSectionsTypeByIds([row.id]).then(() => {
              this.onRefresh();
              this.$message.success("操作成功!");
            });
          })
          .catch(() => {});
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },
    onConfirmEdit(row) {
      if (isEmprty(row.nameCn)) {
        this.$message.warning("中文名称不能为空");
        return false;
      }
      if (isEmprty(row.nameEn)) {
        this.$message.warning("英文名称不能为空");
        return false;
      }
      if (isEmprty(row.seq)) {
        this.$message.warning("排序字段不能为空");
        return false;
      }
      PutPageSectionsTypeById(row.id, {
        address: {
          city: "",
          country: "",
          province: "",
          region: "",
          street: "",
        },
        description: "string",
        name: "string",
        seq: 0,
        typeId: "string",
      })
        .then(() => {
          this.$message.success("修改成功");
          row.isEdit = false;
        })
        .catch((err) => {
          this.onRefresh();
          ShowApiError(err, "修改失败");
        });
    },
    onCancelEdit() {
      this.onRefresh();
    },

    // 批量：启用/禁用
    onMultipleToggleDelete(flag = false) {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let { ids, nameString, unableControl } = FormatMultipleItemIds({
        multipleSelectes: this.multipleSelected,
        conditionsKey: "canDelete",
        nameKey: "nameCn",
      });
      let message = GetTableDisableMessage({
        total: this.multipleSelected.length,
        ableControlCount: ids.length,
        unableControlCount: unableControl.length,
        nameString: nameString,
        flag: flag,
        ableText: "启用",
        unAbleText: "禁用",
      });
      this.$msgbox({
        title: `${flag ? "禁用" : "启用"}提示`,
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (ids.length <= 0) {
              this.$message.info("无有效数据可操作");
              done();
              return;
            }
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            DeletePageSectionsTypeByIds(ids)
              .then(() => {
                this.$message.success("操作成功");
                this.multipleSelected = [];
                this.onRefresh();
              })
              .catch((err) => {
                ShowApiError(err, "操作请求错误");
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },
    onFormCancel() {
      this.formDialog = false;
    },
    onFormSuccess() {
      this.page = 0;
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>